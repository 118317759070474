// Footer

.footer {
  background-color: $dark-green;
  color: $white;
  padding: 1rem 0;

  p {
    color: $white;
    font-size: 14px;
    // font-weight: 300;
    margin: 0 auto;
    max-width: 1000px;
    padding-left: $side-padding-s;
    padding-right: $side-padding-s;

    a {

      &:link,
      &:visited {
        color: $white;
        text-decoration: none;
      }

      &:hover,
      &:active,
      &:focus {
        color: $white-transparent;
      }
    }
  }

  @media only screen and (min-width: 592px) and (max-width: 739px) { // 1-Column with extra side padding
    p {
      padding-left: $side-padding-l;
      padding-right: $side-padding-l;
    }
  }

}




