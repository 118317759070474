// scss-lint:disable MergeableSelector

// DIE MENSCHEN - STYLES FOR CSS-GRID CAPABLE BROWSERS

//////////////////////////////////////////////////////////////////
// DEBUG COLORING ////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

@if $debug-colors {
  .tpl-home { // <body>
    .unit.die-menschen,
    .unit.the-people {
      .grid {
        background: susy-svg-grid() no-repeat scroll;

        .item {
          box-shadow: inset 0 0 0 5px $debug-r;

          &.img {
            outline: 5px solid $debug-r;
            outline-offset: -5px;
          }
        }
      }
    }
  }
} // END: @if $debug-colors

//////////////////////////////////////////////////////////////////
// GRID SETTINGS FOR ALL THREE SECTIONS //////////////////////////
//////////////////////////////////////////////////////////////////

.tpl-home { // <body>
  .unit.die-menschen,
  .unit.the-people {

    .grid {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: stretch;
      margin-bottom: 2rem;
      margin-top: 2rem;

      .item {
        margin-bottom: 1rem;
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }

        &.txt p {
          margin-bottom: 1rem;
          margin-top: 0;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &.img {
          // overflow: hidden;

          img {
            // display: block;
            height: auto;
            max-width: 100%;
            vertical-align: top;
          }
        }
      }
    } // END: .grid

    .grid2 {
      .item7.img,
      .item8.img {
        display: none;
      }

      .wrapper-2-col.first .item.txt {
        margin-bottom: 1rem;
      }

    } // END: .grid2
  } // END: .unit
} // END: .tpl-home


//////////////////////////////////////////////////////////////////
// GRID SETTINGS /////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

// mobile first styles


@media only screen and (max-width: 479px) { // 1-Column with extra side padding
  .tpl-home { // <body>
    .unit.die-menschen,
    .unit.the-people {
      .grid1 {
        .item3.txt {
          margin-bottom: 2.5rem;
          text-align: right;
        }

        .item5.txt {
          margin-bottom: 2.5rem;
        }

        .item7.txt {
          margin-bottom: 2.5rem;
          text-align: right;
        }

        .item2.img, // Herr Nuß
        .item4.img, // Frau Nuß
        .item6.img { // Frau Hannig

          a {
            background-color: $light-grey-transparent;
            background-image: url("../img/envelope.svg");
            background-position: top .85rem right 1.2rem;
            background-repeat: no-repeat;
            background-size: 52px 38px;
            color: $dark-green-mail;
            display: block;
            font-size: 13px;
            letter-spacing: 2px;
            padding: .8rem 5.5rem .85rem 1.2rem;
            text-decoration: none;
            text-transform: uppercase;
            transition: background-color .4s;

            &:hover {
              background-color: $dark-green-transparent;
              background-image: url("../img/envelope-white.svg");
              color: $white;
            }
          }
        }

        .item6.img { // Frau Hannig
          overflow: hidden;
          padding-bottom: calc(120%+65px);
          position: relative;

          a {
            margin-top: 120%;
          }

          img {
            bottom: 64px;
            position: absolute;
          }
        }

        .item8.txt { // Download
          height: 65px;

          a {
            background-color: $light-grey-transparent;
            background-image: url("../img/download.svg");
            background-position: top .55rem left 1.2rem;
            background-repeat: no-repeat;
            background-size: 74px 48px;
            color: $dark-green-mail;
            display: block;
            font-size: 13px;
            letter-spacing: 2px;
            padding: .8rem 1.2rem .85rem 7rem;
            text-decoration: none;
            text-transform: uppercase;
            transition: background-color .4s;

            &:hover {
              background-color: $dark-green-transparent;
              background-image: url("../img/download-white.svg");
              color: $white;
            }
          }
        }
      }
    } // END: .unit
  } // END: .tpl-home
} // END: mq



@media only screen and (min-width: 480px) { // 1-Column with extra side padding

  .tpl-home { // <body>
    .unit.die-menschen,
    .unit.the-people {

      .grid1 {
        .item3.txt {
          margin-bottom: 2.5rem;
          text-align: right;
        }

        .item5.txt {
          margin-bottom: 2.5rem;
        }

        .item7.txt {
          margin-bottom: 2.5rem;
          text-align: right;
        }


        .item2.img, // Herr Nuß
        .item4.img, // Frau Nuß
        .item6.img { // Frau Hannig
          position: relative;

          a {
            background-color: $white-transparent;
            background-image: url("../img/envelope.svg");
            background-position: top .85rem right 1.2rem;
            background-repeat: no-repeat;
            background-size: 52px 38px;
            color: $dark-green-mail;
            display: block;
            font-size: 13px;
            letter-spacing: 2px;
            padding: .8rem 5.5rem .85rem 1.2rem;
            position: absolute;
            text-decoration: none;
            text-transform: uppercase;
            transition: background-color .4s;

            &:hover {
              background-color: $dark-green-transparent;
              background-image: url("../img/envelope-white.svg");
              color: $white;
            }
          }
        }

        .item6.img { // Frau Hannig
          overflow: hidden;
          padding-bottom: 120%;
          position: relative;

          img {
            bottom: 0;
            position: absolute;
          }
        }

        .item8.txt { // Download
          height: 65px;
          position: relative;

          a {
            background-color: $dark-green-transparent;
            background-image: url("../img/download-white.svg");
            background-position: top .55rem left 1.2rem;
            background-repeat: no-repeat;
            background-size: 74px 48px;
            color: $white;
            display: block;
            font-size: 13px;
            letter-spacing: 2px;
            padding: .8rem 1.2rem .85rem 7rem;
            position: absolute;
            text-decoration: none;
            text-transform: uppercase;
            transition: background-color .4s;

            &:hover {
              // background-color: $white-95;
              background-color: $white-transparent;
              background-image: url("../img/download.svg");
              color: $dark-green-mail;
            }
          }
        }

        .item2.img a { // Herr Nuß
          bottom: 2.5rem;
          left: 0;
        }

        .item4.img a { // Frau Nuß
          bottom: .5rem;
          right: 0;
        }

        .item6.img a { // Frau Hannig
          left: 0;
          top: 3.5rem;
        }
      }

      .grid2 {
        .item2.img {
          img {
            width: 100%;
          }
        }

        .item6.txt {
          margin-bottom: 0;

          p:last-child {
            margin-bottom: 0;
          }
        }
      } // END: .grid2
    } // END: .unit
  } // END: .tpl-home
} // END: mq


////////////////////////////////////////////////////////////////////////////////
// for modern browsers with full grid support (excl. some versions of MS Edge)
// Alternative to Modernizr: use @supports (grid-area: auto) {
//                           and @supports not (grid-area: auto) {
////////////////////////////////////////////////////////////////////////////////

@if $css-grid-positioning {

  // @supports (grid-area: auto) {
  :not(.no-cssgrid) {
    .tpl-home {
      .unit.die-menschen,
      .unit.the-people {

        @media only screen and (min-width: 592px) and (max-width: 739px) { // 1-Column with extra side padding

          //
        } // END: mq

        @media only screen and (min-width: 740px) { // 2- & 3-Column CSS-Grid
          .grid {
            display: grid;
            grid-gap: 20px;
            margin-left: auto;
            margin-right: auto;

            .item {
              margin-bottom: 0;
              margin-top: 0;
            }

            .item3.txt,
            .item5.txt,
            .item7.txt {
              margin-bottom: 0;
              text-align: left;
            }

            .item6.img { // Frau Hannig
              height: auto;
              overflow: visible;
              padding-bottom: 0;

              img {
                bottom: ‎auto;
                position: static;
              }
            }
          }
        } // END: mq


        //////////////////////////////////////////////////////////////////////////
        // 2-Column CSS-Grid /////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////

        @media only screen and (min-width: 740px) and (max-width: 1039px) {
          .grid {
            grid-template-columns: 1fr 1fr;

            .item.img ,
            .item.txt {
              margin-top: 0;
              max-width: none;
              width: 100%;

              p:first-child {
                margin-top: 0;
              }

              img {
                display: block;
              }
            }
          } // END: .grid

          .grid1 {
            grid-template-rows: auto auto auto .5fr auto 1fr auto auto;

            .item1.txt {
              grid-column: 1 / span 2;
              grid-row: 1 / span 1;
            }

            .item2.img {
              grid-column: 1 / span 2;
              grid-row: 2 / span 1;
            }

            .item3.txt {
              grid-column: 2 / span 1;
              grid-row: 3 / span 1;
              // text-align: right;
            }

            .item4.img {
              grid-column: 2 / span 1;
              grid-row: 5 / span 1;
            }

            .item5.txt {
              align-self: end;
              grid-column: 2 / span 1;
              grid-row: 4 / span 1;
              text-align: right;
            }

            .item6.img {
              grid-column: 1 / span 1;
              grid-row: 3 / span 6;
            }

            .item7.txt {
              align-self: end;
              grid-column: 2 / span 1;
              grid-row: 8 / span 1;
            }

            .item8.txt {
              align-self: center;
              grid-column: 2 / span 1;
              grid-row: 6 / span 1;
            }


          } // END: .grid1

          .grid2 {

            .item1.txt {
              grid-column: 1 / span 2;
              grid-row: 1 / span 1;
            }

            .item2.img {
              grid-column: 1 / span 2;
              grid-row: 2 / span 1;
            }

            .item4.txt {
              grid-column: 1 / span 1;
              grid-row: 4 / span 1;
            }

          } // END: .grid1
        }



        //////////////////////////////////////////////////////////////////////////
        // 3-Column CSS-Grid /////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////

        @media only screen and (min-width: 1040px) { // 3-Column CSS-Grid
          .grid {
            grid-template-columns: 1fr 1fr 1fr;

            .item.img ,
            .item.txt {
              margin: 0;
              max-width: none;
              width: 100%;

              img {
                display: block;
              }

              p:first-child {
                margin: 0;
              }
            }
          }

          .grid1 {
            grid-template-rows: auto 340px 92px auto;

            .item2.img { // Herr Nuß
              grid-column: 1 / span 2;
              grid-row: 2 / span 1;
            }

            .item3.txt {
              align-self: end;
              grid-column: 2 / span 1;
              grid-row: 1 / span 1;
              text-align: right;

              p:first-child,
              p {
                margin-bottom: 1rem;
              }
            }

            .item4.img { // Frau Nuß
              grid-column: 2 / span 1;
              grid-row: 3 / span 2;
            }

            .item5.txt { // Frau Nuß
              align-self: end;
              grid-column: 1 / span 1;
              grid-row: 4 / span 1;
              text-align: right;
            }

            .item6.img { // Frau Hannig
              grid-column: 3 / span 1;
              grid-row: 1 / span 3;
            }

            .item7.txt { // Frau Hannig
              grid-column: 3 / span 1;
              grid-row: 4 / span 1;
            }

            .item8.txt { // Download
              align-self: end;
              grid-column: 1 / span 1;
              grid-row: 3 / span 1;
            }

          } // END: .grid1

          .grid2 {
            grid-template-rows: min-content min-content 340px min-content;

            .item7.img,
            .item8.img {
              display: block;
            }

            .item1.txt {
              align-self: start;
              grid-column: 1 / span 1;
              grid-row: 1 / span 1;
            }

            .item2.img { // Haus 12
              grid-column: 2 / span 2;
              grid-row: 3 / span 1;
            }

            .item3.txt {
              align-self: end;
              grid-column: 1 / span 1;
              grid-row: 2 / span 1;
            }

            .item5.txt {
              align-self: start;
              grid-column: 3 / span 1;
              grid-row: 1 / span 1;
            }

            .item4.txt {
              align-self: start;
              grid-column: 2 / span 1;
              grid-row: 4 / span 1;
            }

            .item6.txt {
              align-self: end;
              grid-column: 3 / span 1;
              grid-row: 2 / span 1;
            }

            .item7.img { // Barfuß
              grid-column: 2 / span 1;
              grid-row: 1 / span 2;
            }

            .item8.img {
              grid-column: 1 / span 1;
              grid-row: 3 / span 2;
            }
          } // END: .grid2
        } // END: mq
      } // END: .unit
    } // END: .tpl-home
  } // END: @supports
} // END: @if $css-grid-positioning

// scss-lint:enable MergeableSelector
