// scss-lint:disable MergeableSelector

@if $absolute-positioning {

  // DIE MENSCHEN - FALLBACK STYLES FOR BROWSERS WITHOUT CSS-GRID SUPPORT
  // CAN BE REMOVED WHEN NO LONGER NEEDED

  //////////////////////////////////////////////////////////////////
  // GRID SETTINGS FOR ALL THREE SECTIONS //////////////////////////
  //////////////////////////////////////////////////////////////////

  // @supports not (grid-area: auto) {
  .no-cssgrid {
    .tpl-home {
      .unit.die-menschen,
      .unit.the-people {
        @media only screen and (min-width: 1040px) { // 3-Column Absolute Positioned
          .grid {
            position: relative;
          }
        }
      }
    }

  //////////////////////////////////////////////////////////////////
  // for classic browsers without grid support, absolute positioning
  // might be removed one day, when cssgrid is ubiquitous
  //////////////////////////////////////////////////////////////////

    .tpl-home {
      .unit.die-menschen,
      .unit.the-people {

        .item.txt {
          overflow: hidden;
        }

        @media only screen and (min-width: 740px) and (max-width: 1039px) { // 2-Column Absolute Positioned
          .grid1 {
            display: block; // removes flex!

            .item3.txt,
            .item4.img,
            .item5.txt,
            .item6.img,
            .item7.txt,
            .item8.txt {
              margin-left: calc(50% + 10px);
              width: calc(50% - 10px);
            }

            .item3.txt,
            .item5.txt,
            .item7.txt {
              margin-bottom: 20px;
            }

            .item6.img {
              // float: left;
              margin-left: 0;
              margin-top: calc(-43% - 139px);
              padding-bottom: 0; // reset
              padding-top: 70%;
              // position: relative;

              img {
                bottom: 0;
                height: auto;
                position: absolute;
                width: 100%;
              }
            }

            .item4.img {
              margin-bottom: 20px;
            }

            .item5.txt {
              text-align: right;
            }

            .item7.txt {
              margin-top: -60px;
              text-align: left;
            }

            .item8.txt {
              height: 65px;
            }
          } // END: .grid1

          .grid2 {
            @include cf();
            display: block; // removes flex!

            .item3.txt,
            .item4.txt,
            .item5.txt,
            .item6.txt {
              margin-left: calc(50% + 10px);
              width: calc(50% - 10px);
            }

            .item3.txt,
            .item4.txt {
              clear: left;
              float: left;
              margin-left: 0;
              margin-right: 20px;
            }

            .item4.txt {
              margin-bottom: 0;
            }


          } // END: .grid2
        } // END: mq


        @media only screen and (min-width: 1040px) { // 3-Column Absolute Positioned

          .grid1 {
            height: 740px;

            .item.txt,
            .item.img { margin: 0; position: absolute;}

            .item1.txt { height: 80px;  left: 0;     top: 0;     width: 320px; }
            .item2.img { height: 340px; left: 0;     top: 100px; width: 660px; }
            .item3.txt { height: 80px;  left: 340px; top: 0;     width: 320px; p { bottom: 0; position: absolute; right: 0; }}
            .item4.img { height: 280px; left: 340px; top: 460px; width: 320px; }
            .item5.txt { height: 125px; left: 0;     top: 615px; width: 320px; p { bottom: 0; position: absolute; right: 0; text-align: right; }}
            .item6.img { height: 555px; left: 680px; top: 0;     width: 320px; padding-bottom: 0; img { position: static; }}
            .item7.txt { height: 165px; left: 680px; top: 575px; width: 320px; }
            .item8.txt { height: 65px;  left: 0;     top: 530px; width: 320px; }
          } // END: .grid1

          .grid2 {
            height: 918px;

            .item.txt,
            .item.img { margin: 0; position: absolute;}

            .item1.txt { height: 80px;  left: 0;     top: 0;     width: 320px; }
            .item2.img { height: 340px; left: 340px; top: 363px; width: 660px; }
            .item3.txt { height: 243px; left: 0;     top: 100px; width: 320px; p { bottom: 0; position: absolute; }}
            .item4.txt { height: 195px; left: 340px; top: 723px; width: 320px; }
            .item5.txt { height: 170px; left: 680px; top: 0;     width: 320px; }
            .item6.txt { height: 161px; left: 680px; top: 182px; width: 320px; }
            .item7.img { height: 343px; left: 340px; top: 0;     width: 320px; }
            .item8.img { height: 555px; left: 0;     top: 363px; width: 320px; }
            .item7.img,
            .item8.img {
              display: block;
            }
          } // END: .grid2
        } // END: mq
      } // END: .unit
    } // END: .tpl-home
  } // END: @supports not (grid-area: auto)
} // END: @if $absolute-positioning
// scss-lint:enable MergeableSelector
