// HOME

.tpl-home { // <body>
  .unit.steinzeit {

    .grid {
      .item {
        &.txt {
          p {
            margin-top: 0;
          }
        }

        &.img {
          img {
            height: auto;
            width: 100%;
          }
        }
      }
    } // END: .grid

    .divider.divider-beige-grey {
      .outer-wrap {
        flex: 0 1 1000px;
      }
    }

    .grid1 {
      display: flex;
      flex-wrap: wrap;
      justify-content: stretch;
      margin-bottom: 1.5rem;

      .col1 {
        align-self: flex-end;
        width: susy-span(12 of 12);
      }

      .col2 {
        align-self: flex-end;
        margin-right: 0;
        width: susy-span(12 of 12);
      }

      .col3 {
        display: none;
      }

    } // END: .grid1


    .grid2 {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1rem;
      margin-top: 1rem;

      .item {
        align-self: center;
      }

      .item1 {
        width: susy-span(12 of 12);
      }

      .item2 {
        width: susy-span(12 of 12);
      }
    } // END: .grid2


    .grid3 {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0;
      margin-top: 2rem;

      .item1.img {
        display: none;
      }

      .item2.img {
        margin-bottom: 1rem;
        width: susy-span(12 of 12);
      }

      .item3.txt {
        margin-bottom: 2rem;
      }

      .col1 {
        display: flex;
        width: susy-span(12 of 12);
      }

      .col2 {
        width: susy-span(12 of 12);
      }
    } // END: .grid3


    .grid4 {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0;
      margin-top: 2rem;

      .item1.txt {
        width: susy-span(12 of 12);
      }

      .item2.img,
      .item3.img {
        display: none;
      }

      .item4.img {
        width: susy-span(12 of 12);
      }
    } // END: .grid4

    @media only screen and (max-width: 479px) { // 1-Column Layout
      .grid1 {
        .col1 {
          margin-bottom: 1rem;
        }

        .col2 {
          .item3.txt p {
            margin-bottom: 0;
          }

          .item4.img {
            display: none;
          }
        }
      } // END: .grid1

    }

    @media only screen and (min-width: 480px) and (max-width: 739px) { // 1-Column Layout
      .grid1 {
        .col1 {
          margin-bottom: 1rem;

          .item2.img {
            margin-left: susy-span(3 of 12)+susy-gutter(1 of 12);
          }
        }

        .col2 {
          .item4.img {
            width: susy-span(10 of 12);
          }
        }
      } // END: .grid1

      .grid2 {
        .item {
          align-self: center;
        }

        .item1 {
          // margin-right: 0;
          width: susy-span(12 of 12);
        }

        .item2 {
          // margin-right: 0;
          margin-left: susy-span(2 of 12)+susy-gutter(1 of 12);
          width: susy-span(10 of 12);
        }
      } // END: .grid2

      .grid3 {
        .item2.img {
          margin-bottom: 1rem;
          width: susy-span(10 of 12);
        }

        .item3.txt {
          margin-bottom: 50px;
        }

        .col1 {
          display: flex;
          width: susy-span(12 of 12);
        }

        .col2 {
          width: susy-span(10 of 12);
        }
      } // END: .grid3

      .grid4 {
        .item1.txt {
          margin-left: susy-span(2 of 12)+susy-gutter(1 of 12);
          width: susy-span(10 of 12);
        }

        .item2.img,
        .item3.img {
          display: none;
        }

        .item4.img {
          margin-left: susy-span(2 of 12)+susy-gutter(1 of 12);
          width: susy-span(10 of 12);
        }
      } // END: .grid4


    }

    @media only screen and (min-width: 740px) and (max-width: 1039px) { // 2-Column Layout
      .grid1 {
        .col1 {
          align-self: flex-end;
          margin-right: susy-gutter(1 of 12);
          width: susy-span(4 of 12);
        }

        .col2 {
          align-self: flex-end;
          display: flex;
          flex-direction: column-reverse;
          margin-left: susy-span(1 of 12)+susy-gutter(1 of 12);
          margin-right: 0;
          width: susy-span(7 of 12);

          .item3.txt p {
            margin-bottom: 0;
            margin-top: 1rem;
          }
        }
      } // END: .grid1

      .grid2 {
        .item {
          align-self: center;
        }

        .item1 {
          margin-right: susy-gutter(1 of 12);
          width: susy-span(12 of 12);
        }

        .item2 {
          margin-left: susy-span(3 of 12)+susy-gutter(1 of 12);
          width: susy-span(9 of 12);
        }
      } // END: .grid2

      .grid3 {
        .item2.img {
          margin-bottom: 1rem;
          width: susy-span(9 of 12);
        }

        .item3.txt {
          margin-bottom: 50px;
        }

        .col1 {
          display: flex;
          width: susy-span(12 of 12);
        }

        .col2 {
          // margin-right: 0;
          width: susy-span(8 of 12);
        }
      } // END: .grid3

      .grid4 {
        .item1.txt {
          margin-left: susy-span(2 of 12)+susy-gutter(1 of 12);
          width: susy-span(9 of 12);
        }

        .item2.img,
        .item3.img {
          display: none;
        }

        .item4.img {
          margin-left: susy-span(2 of 12)+susy-gutter(1 of 12);
          width: susy-span(9 of 12);
        }
      } // END: .grid4

    }


    @media only screen and (min-width: 1040px) { // 3-Column Layout
      .grid1 {
        .col1 {
          align-self: flex-end;
          margin-right: susy-gutter(1 of 12);
          width: susy-span(3 of 12);
        }

        .col2 {
          align-self: flex-end;
          margin-right: susy-gutter(1 of 12);
          width: susy-span(6 of 12);
        }

        .col3 {
          align-self: flex-start;
          display: block;
          margin-right: 0;
          width: susy-span(3 of 12);
        }
      } // END: .grid1

      .grid2 {
        .item {
          align-self: center;
        }

        .item1 {
          margin-right: susy-gutter(1 of 12);
          width: susy-span(5 of 12);
        }

        .item2 {
          margin-right: 0;
          width: susy-span(7 of 12);
        }
      } // END: .grid2

      .grid3 {
        .item1.img {
          display: block;

          img {
            display: block;
            margin-bottom: 20px;
            width: 209px;
          }
        }


        .item2.img img {
          display: block;
          margin-bottom: 50px;
          width: 560px;
        }

        .item3.txt {
          margin-bottom: 50px;
        }

        .col1 {
          display: block;
          margin-right: susy-gutter(1 of 12);
          width: susy-span(8 of 12);
        }

        .col2 {
          margin-right: 0;
          width: susy-span(4 of 12);
        }
      } // END: .grid3

      .grid4 {
        .item1.txt {
          margin-bottom: 20px;
          margin-right: susy-gutter(1 of 12);
          width: susy-span(7 of 12);
        }

        .item2.img {
          display: block;
          margin-bottom: 20px;
          margin-right: 0;
          width: susy-span(5 of 12);
        }

        .item3.img {
          display: block;
          margin-right: 2*susy-gutter(1 of 12)+susy-span(2 of 12);
          width: susy-span(4 of 12);
        }

        .item4.img {
          margin-right: 0;
          width: susy-span(6 of 12);
        }
      } // END: .grid4
    } // END: mq
  }
}
