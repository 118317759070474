// Navigation



// Responsive

nav {

  .menu-desktop-top,
  .menu-desktop-sticky.enabled {
    display: none;
  }

  @media (min-width: 830px) {
    .menu-desktop-top,
    .menu-desktop-sticky.enabled {
      display: block;
    }

    .menu-mobile {
      display: none;
    }
  }
}



// General

nav {
  width: 100%;
  z-index: 1000;

  // Shared list styles for top & sticky menu

  .menu-desktop-top,
  .menu-desktop-sticky {
    

    ul {
      float: right;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        float: left;
        padding: 0;

        a {
          border: 0;
          cursor: pointer;
          display: inline-block;
          font-size: 12px;
          font-weight: 400;
          height: 20px;
          line-height: 1.2; // normal
          margin: 0 10px 20px;
          padding: 25px 0 16px;
          position: relative;
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;
          z-index: 2;
        }
      }
    }
  }

  // Top menu for desktop

  .menu-desktop-top {
    margin-right: 15px;
    position: static;

    ul {
      li {
        a {
          color: $dark-green;

          &.active {
            border-bottom: 2px solid $dark-green;
          }
        }
      }
    }
  }



  // Sticky top menu for desktop while scrolling, starts at 80px below top of page

  .menu-desktop-sticky {
    background-color: $dark-green;
    display: none;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 100;

    .left {
      float: left;
      padding-left: 15px;
      width: 15%;

      .menu-desktop-sticky-logo {
        box-sizing: content-box;
        height: 50px;
        margin-left: 17px;
        padding-bottom: 9px;
        padding-top: 11px;
        width: 110px;
      }
    }

    .right {
      float: left;
      padding-right: 15px;
      width: 85%;
    }

    ul {
      li {
        a {
          color: $white;

          &.active {
            border-bottom: 2px solid $white;
          }
        }
      }
    }
  }

  // Mobile Menu with Hamburger Button

  .menu-mobile {
    position: fixed;
    right: 0;
    top: 0;
    cursor: pointer;
    float: right;
    padding: 20px;
    z-index: 100;

    .hamburger {
      height: 36px;
      margin-right: 5px;
      margin-top: 0;
      position: relative;
      transform: rotate(0);
      width: 36px;
      z-index: +1;

      &:after {
        background-color: rgba($white, .66);
        border-radius: 50%;
        content: "";
        display: block;
        height: 52px;
        left: -8px;
        position: absolute;
        top: -9px;
        width: 52px;
        z-index: 0;
      }

      span {
        background: $dark-green;
        display: block;
        height: 3px;
        left: 0;
        opacity: 1;
        position: absolute;
        transform: rotate(0deg);
        transition: 100ms ease;
        width: 100%;
        z-index: +1;

        &:nth-child(1) {
          top: 6px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          top: 15px;
        }

        &:nth-child(4) {
          top: 24px;
        }
      }

      &.open span {
        &:nth-child(1) {
          left: 50%;
          top: 18px;
          width: 0%;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }

        &:nth-child(4) {
          left: 50%;
          top: 18px;
          width: 0%;
        }
      }
    }

    .menu-mobile-entries {
      padding-top: 15px;
      position: absolute;
      right: 0;
      top: 56px;
      transform: translateX(130%);
      transition: 200ms ease;
      z-index: 2;

      &.open {
        transform: translateX(0);
      }

      ul {
        margin: 0;
      }

      li {
        background-color: $white-95;
        border-bottom: 1px solid $medium-grey;
        list-style-type: none;
        padding: 7px 20px;
        white-space: nowrap;

        &:first-child {
          border-top: 1px solid $medium-grey;
        }

        a {
          color: $dark-medium-grey;
          display: block;
          font-size: 14px;
          line-height: 1.6;
          position: relative;
          text-decoration: none;
          text-transform: uppercase;

          &.active {
            color: $light-grey;
          }
        }
      }

    }
  } // END: .menu-mobile

} // END: nav







