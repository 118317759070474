// scss-lint:disable MergeableSelector

@if $absolute-positioning {

  // DAS HAUS - FALLBACK STYLES FOR BROWSERS WITHOUT CSS-GRID SUPPORT
  // CAN BE REMOVED WHEN NO LONGER NEEDED

  //////////////////////////////////////////////////////////////////
  // GRID SETTINGS FOR ALL THREE SECTIONS //////////////////////////
  //////////////////////////////////////////////////////////////////

  // @supports not (grid-area: auto) {
  .no-cssgrid {
    .tpl-home {
      .unit.das-haus,
      .unit.the-house {
        @media only screen and (min-width: 1040px) { // 3-Column Absolute Positioned
          .grid {
            position: relative;
          }
        }
      }
    }

  //////////////////////////////////////////////////////////////////
  // for classic browsers without grid support, absolute positioning
  // might be removed one day, when cssgrid is ubiquitous
  //////////////////////////////////////////////////////////////////

    .tpl-home {
      .unit.das-haus,
      .unit.the-house {

        .item.txt {
          overflow: hidden;
        }

        @media only screen and (min-width: 740px) and (max-width: 1039px) { // 2-Column Absolute Positioned
          .grid1 {
            @include cf();
            display: block; // removes flex!

            .item3.txt,
            .item4.txt {
              clear: left;
              float: left;
              margin-left: 0;
              margin-right: 20px;
              width: calc(50% - 10px);
            }

            .item7.txt {
              width: calc(50% - 10px);
            }
          } // END: .grid1

          .grid2 {
            @include cf();
            display: block; // removes flex!

            .item3.txt,
            .item5.txt {
              clear: left;
              float: left;
              margin-left: 0;
              margin-right: 20px;
              width: calc(50% - 10px);
            }

            .item6.txt,
            .item7.txt {
              width: calc(50% - 10px);
            }
          } // END: .grid2

          .grid3 {
            @include cf();
            display: block; // removes flex!

            .item5.txt {
              clear: left;
              float: left;
              margin-left: 0;
              margin-right: 20px;
              width: calc(50% - 10px);
            }

            .item6.txt {
              width: calc(50% - 10px);
            }
          } // END: .grid3

          .grid4 {
            @include cf();
            display: block; // removes flex!

            .item1.txt,
            .item2.txt {
              clear: left;
              float: left;
              margin-left: 0;
              margin-right: 20px;
              width: calc(50% - 10px);
            }

            .item3.txt,
            .item4.txt {
              width: calc(50% - 10px);
            }

            .item5.img {
              clear: both;
            }

          } // END: .grid4

        } // END: mq


        @media only screen and (min-width: 1040px) { // 3-Column Absolute Positioned

          .grid1 {
            height: 814px;

            .item.txt,
            .item.img { margin: 0; position: absolute;}

            .item5.img,
            .item6.img { display: block; }

            .item1.txt { height: 80px;  left: 0;     top: 0;     width: 320px; }
            .item2.img { height: 328px; left: 0;     top: 188px; width: 660px; }
            .item3.txt { height: 168px; left: 340px; top: 0;     width: 320px; p { bottom: 0; position: absolute; right: 0; }}
            .item4.txt { height: 278px; left: 0;     top: 536px; width: 320px; }
            .item5.img { height: 516px; left: 680px; top: 0;     width: 320px; }
            .item6.img { height: 278px; left: 340px; top: 536px; width: 320px; }
            .item7.txt { height: 278px; left: 680px; top: 536px; width: 320px; }
          } // END: .grid1

          .grid2 {
            height: 830px;

            .item.txt,
            .item.img { margin: 0; position: absolute;}

            .item4.img,
            .item8.img { display: block; }

            .item1.txt { height: 80px;  left: 0;     top: 0;     width: 320px; }
            .item2.img { height: 328px; left: 0;     top: 292px; width: 660px; }
            .item3.txt { height: 172px; left: 0;     top: 100px; width: 320px; }
            .item4.img { height: 272px; left: 340px; top: 0;     width: 320px; }
            .item5.txt { height: 190px; left: 0;     top: 640px; width: 320px; }
            .item6.txt { height: 190px; left: 340px; top: 640px; width: 320px; }
            .item7.txt { height: 322px; left: 680px; top: 0;     width: 320px; }
            .item8.img { height: 488px; left: 680px; top: 342px; width: 320px; }
          } // END: .grid2

          .grid3 {
            height: 996px;

            .item.txt,
            .item.img { margin: 0; position: absolute;}

            .item3.img,
            .item4.img { display: block; }

            .item1.txt { height: 80px;  left: 0;     top: 0;     width: 320px;  }
            .item2.img { height: 569px; left: 0;     top: 405px; width: 1250px; }
            .item3.img { height: 385px; left: 340px; top: 0;     width: 320px;  }
            .item4.img { height: 218px; left: 680px; top: 0;     width: 320px;  }
            .item5.txt { height: 285px; left: 0;     top: 100px; width: 320px; p { bottom: 0; position: absolute; right: 0; }}
            .item6.txt { height: 147px; left: 680px; top: 238px; width: 320px; p { bottom: 0; position: absolute; right: 0; }}
          } // END: .grid3

          .grid4 {
            height: 437px;

            .item.txt,
            .item.img { margin: 0; position: absolute;}

            .item4.img,
            .item8.img { display: block; }

            .item1.txt { height: 147px; left: 0;     top: 0;     width: 320px;  }
            .item2.txt { height: 147px; left: 340px; top: 0;     width: 320px;  }
            .item3.txt { height: 147px; left: 680px; top: 0;     width: 320px;  }
            .item4.txt { height: 270px; left: 0;     top: 167px; width: 320px;  }
            .item5.img { height: 270px; left: 340px; top: 167px; width: 1005px; }
          } // END: .grid4
        } // END: mq
      } // END: .unit
    } // END: .tpl-home
  } // END: @supports not (grid-area: auto)
} // END: @if $absolute-positioning
// scss-lint:enable MergeableSelector
