// AAGD Additions


// Edit Links for Sections

$edit-link-color: #aaa;
$edit-link-border-color: #bbb;
$edit-link-bg-color: #fff;
$edit-link-border-color-hover: #d55;

.edit a,
h1 .edit a,
h2 .edit a,
h3 .edit a {
  background-color: $edit-link-bg-color;
  border: 2px solid $edit-link-border-color;
  border-radius: 5px;
  color: $edit-link-color;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0;
  padding: 3px 8px;
  text-decoration: none;
  text-transform: capitalize;
  vertical-align: baseline;

  &:hover {
    border-color: $edit-link-border-color-hover;
    color: $edit-link-border-color-hover;
  }
}

.edit {
  float: right;
  left: 0;
  position: absolute;
  top: -1.5rem;
  z-index: 1;

  // @media only screen and (min-width: 592px) and (max-width: 739px) { // 1-Column with extra side padding
  //   left: $side-padding-l;
  // }
}


.debug {
  background-color: rgba(255, 0, 0, 0.25)!important;
  box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

#page-small-kontakt .edit {
  top: -1rem;
}



// Steinzeit

.section-steinzeit {

  strong {
    color: $dark-green;
  }

  h2 {
    font-size: 36px;
    letter-spacing: 1px;
  }
  .page-workshops {
    h2 {
      padding-top: 60px;
    }
  }
  .page-personalities {
    h2 {
      padding-top: 50px;
    }
  }

}


.bg--green {
  background-color: $dark-green;
  color: $white;
}

.bg--beige {
  background-color: $beige;
  color: $grey;
}

.bg--light-blue {
  background-color: $light-blue;
  color: $light-medium-grey;
}

.divider-green-new,
.divider-blue-new {
  background-color: $dark-green;
  clear: both;
  margin-top: 2px;
  width: 100%;

  .row {
    color: $white;
    padding-bottom: 0px;
    padding-left: 15px;
    padding-top: 24px;

    .large {
      font-family: 'utopia-std-display', serif;
      font-size: 28px;
      letter-spacing: 2px;
      line-height: 1;
    }

    .small {
      font-size: 14px;
      line-height: 1;
      margin-bottom: .5rem;

    }
  }
}

@media screen and (min-width: 64.0625em) {

  .divider-green-new,
  .divider-blue-new {


    .row {
      color: $white;
      padding-bottom: 15px;
      padding-left: 15px;
      padding-top: 36px;

      .large {
        font-size: 46px;
      }

      .small {
        font-size: 14px;
      }
    }
  }
  .divider-blue-new {


    .row {
      .large {
        font-size: 36px;
      }
    }
  }

}


.divider-blue-new {
  background-color: $light-blue;
  .row {
    color: $light-medium-grey;
    // margin-left: 30px;
  }
}


.divider-beige-new {
  border-right: 15px solid $white;
  border-left: 15px solid $white;
  background-color: $beige;
  clear: both;
  color: $grey;
  margin-top: 2px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-top: 36px;
  width: 100%;

  section {
    display: inline-block;
    float: right;
    padding-right: 30px;
  }

  .large {
    font-family: 'utopia-std-display', serif;
    font-size: 46px;
    letter-spacing: 2px;
    line-height: 1;
  }

  .small {
    font-size: 14px;
    line-height: 1;
    margin-bottom: .5rem;

  }
}

.divider-beige-new-small {
  background-color: $beige;
  clear: both;
  margin-top: 2px;
  width: 100%;

  .row {
    color: $grey;
    padding-bottom: 0px;
    padding-left: 15px;
    padding-top: 24px;

    .large {
      font-family: 'utopia-std-display', serif;
      font-size: 28px;
      letter-spacing: 2px;
      line-height: 1;
    }

    .small {
      font-size: 14px;
      line-height: 1;
      margin-bottom: .5rem;

    }
  }
}









