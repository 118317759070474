// scss-lint:disable MergeableSelector

@if $absolute-positioning {

  // COMPETENCIES - FALLBACK STYLES FOR BROWSERS WITHOUT CSS-GRID SUPPORT
  // CAN BE REMOVED WHEN NO LONGER NEEDED

  //////////////////////////////////////////////////////////////////
  // GRID SETTINGS FOR ALL THREE SECTIONS //////////////////////////
  //////////////////////////////////////////////////////////////////

  // @supports not (grid-area: auto) {
  .no-cssgrid {
    .tpl-home {
      .unit.competencies,
      .unit.kompetenzen {
        @media only screen and (min-width: 1040px) { // 3-Column Absolute Positioned
          .grid {
            position: relative;
          }
        }
      }
    }

    //////////////////////////////////////////////////////////////////
    // for classic browsers without grid support, absolute positioning
    // might be removed one day, when cssgrid is ubiquitous
    //////////////////////////////////////////////////////////////////

    .tpl-home {
      .unit.competencies,
      .unit.kompetenzen {

        .item.txt {
          overflow: hidden;
        }

        .grid {
          @include cf();
        }

        @media only screen and (min-width: 740px) and (max-width: 1039px) { // 2-Column Absolute Positioned
          .grid1 {
            .item {
              margin: 20px 0;
              width: calc(50% - 10px);
            }

            .item1 {
              padding-bottom: 20px;
            }

            .item2 {
              width: 100%;
            }

            .item3 {
              display: block;
              float: right;
              height: 230px;
              position: relative;
              right: 0;
              top: -20px;
            }

            .item3:after {
              content: "";
              display: block;
              padding-bottom: 92.22222222%; // 360x332
            }
          } // END: .grid1
          .grid2 {
            .item {
              margin: 20px 0;
              width: calc(50% - 10px);
            }

            .item1 {
              padding-bottom: 20px;
            }

            .item2 { // Herr Nuß
              float: right;
              margin-bottom: 0;
              position: relative;
              top: -20px;
            }

          } // END: .grid2
          .grid3 {
            .item {
              margin: 20px 0;
              width: calc(50% - 10px);
            }

            .item1 {
              padding-bottom: 20px;
            }

            .item2 {
              float: left;
              position: relative;
              right: 0;
              top: -20px;
            }


            .item3,
            .item4 {
              display: block;
              margin-left: calc(50% + 10px);
            }
          } // END: .grid3
        } // END: mq


        @media only screen and (min-width: 1040px) { // 3-Column Absolute Positioned

          .grid1 {
            height: 865px;
            margin-bottom: -20px;

            .item { position: absolute; }
            .item1 { height: 80px;  left: 0;     top: 0;     width: 320px; }
            .item2 { height: 340px; left: 0;     top: 230px; width: 660px; }
            .item3 { height: 275px; left: 340px; top: 590px; width: 320px; }
            .item4 { height: 235px; left: 0;     top: 590px; width: 320px; }
            .item5 { height: 210px; left: 340px; top: 0;     width: 320px; }
            .item6 { height: 635px; left: 680px; top: 0;     width: 320px; img { height: 100%; width: auto;}}
            .item7 { height: 170px; left: 680px; top: 655px; width: 320px; }
            .item2 { padding-bottom: 0; }
            .item3,
            .item6,
            .item7 { display: block; }
          } // END: .grid1

          .grid2 {
            height: 684px;
            margin-bottom: -20px;

            .item { position: absolute; }
            .item1 { height: 80px;  left: 0;     top: 0;     width: 320px; }
            .item2 { height: 386px; left: 340px; top: 0;     width: 320px; }
            .item3 { height: 286px; left: 0;     top: 100px; width: 320px; p { bottom: 0; position: absolute; }}
            .item4 { height: 418px; left: 680px; top: 226px; width: 320px; }
            .item5 { height: 278px; left: 0;     top: 406px; width: 660px; }
            .item6 { height: 206px; left: 680px; top: 0;     width: 320px; }
            .item7 { height: 210px; left: 680px; top: 655px; width: 320px; }
            .item2 { padding-bottom: 0; }
            .item2,
            .item5,
            .item6 { display: block; }

            .item2,
            .item5 {
              img {
                position: absolute;
              }
            }
          } // END: .grid2

          .grid3 {
            height: 729px;
            margin-bottom: 20px;

            .item { position: absolute; }
            .item1 { height: 80px;  left: 0;     top: 0;     width: 320px; }
            .item2 { height: 339px; left: 340px; top: 390px; width: 660px; }
            .item3 { height: 320px; left: 0;     top: 100px; width: 320px; }
            .item4 { height: 370px; left: 680px; top: 0;     width: 320px; }
            .item5 { height: 313px; left: 340px; top: 0;     width: 320px; display: block;}
            .item6 { height: 339px; left: 0;     top: 440px; width: 320px; }
            .item7 { height: 210px; left: 680px; top: 655px; width: 320px; }
            .item2 { padding-bottom: 0; }
            .item5,
            .item6 { display: block; }

            .item2,
            .item5 {
              img {
                position: absolute;
              }
            }
          } // END: .grid3


        } // END: mq
      } // END: .kompetenzen
    } // END: .tpl-home
  } // END: @supports not (grid-area: auto)
} // END: @if $absolute-positioning
// scss-lint:enable MergeableSelector
