// GENERAL
// Site-specific global settings


body {
  background-color: $white;
  color: $grey;
  min-width: 318px;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}


//////////////////////////////////////////////////////////////////
// SIDE MARGINS //////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

.sidemargins {
  margin: 0 auto;
  max-width: 1040px;
  padding: 0 $side-padding-s;
}

@media only screen and (min-width: 592px) and (max-width: 739px) { // 1-Column with extra side padding
  .sidemargins {
    padding: 0 $side-padding-l;
  }
} // END: mq


//////////////////////////////////////////////////////////////////
// UNIT HEADLINE /////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

.page,
.unit {

  .page-header,
  .unit-header {
    height: auto;
    margin: 2.5rem 0 0;
    // max-width: 1040px;
    // padding: 0;
    position: relative;
    text-transform: uppercase;

    .page-hl,
    .unit-hl {
      color: $light-grey;
      font-size: 136px;
      font-weight: 400;
      line-height: .65;
      margin: 0;
      padding: 13px 0 10px;
      vertical-align: bottom;
    }

    .page-shl,
    .unit-shl {
      font-size: 17px;
      font-weight: 400;
      line-height: normal;
      margin: 0 0 40px;
      padding-left: 2px;
      padding-top: 5px;
    }
  }

  @media only screen and (min-width: 592px) and (max-width: 739px) { // 1-Column with extra side padding
    .unit-header {
      // padding: 0 ($side-padding-l - $side-padding-s);
    }
  } // END: mq
}

.chapter {

  .chapter__header {
    color: $dark-green;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  .chapter__hl {
    font-size: 46px;
    line-height: 36px;
    margin-bottom: 3px;
  }

  .chapter__shl {
    font-weight: 400;
  }

  .chapter__body {
    margin-bottom: 1rem;
  }

}



.centered-row {
  margin: 0 auto;
  max-width: 62.5rem;
  width: 100%;
}
