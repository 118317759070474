// CONTACT

.tpl-home { // <body>
  .unit.contact,
  .unit.kontakt {


    .content-bar {
      a {
        &:link,
        &:visited {
          color: $email-link;
          text-decoration: none;
        }

        &:hover,
        &:active,
        &:focus {
          color: $email-link-hover;
        }
      }

      .grid {
        margin: 0 auto 2rem;
        max-width: 1040px;
        padding-left: $side-padding-s;
        padding-right: $side-padding-s;

        .grid-item {
          margin-bottom: 1rem;
        }
      }
    }

    .map {
      display: none;
    }

    .map-img {
      width: 100%;
    }

    @media only screen and (min-width: 592px) and (max-width: 739px) { // 1-Column with extra side padding
      .content-bar {
        .grid {
          padding-left: $side-padding-l;
          padding-right: $side-padding-l;
        }
      }
    }

    @media only screen and (min-width: 740px) { // 2- & 3-Column CSS-Grid

      .content-bar {
        background-color: $dark-green;
        color: $white;
        padding: 2rem 0;

        a {
          color: $white;

          &:link,
          &:visited {
            color: $white;
          }

          &:hover,
          &:active,
          &:focus {
            color: $white-transparent;
          }
        }

        .grid {
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          margin: 0 auto;

          .grid-item {
            display: inline-block;
            flex: 0 1 33.33333%;
            margin-bottom: 0;
            margin-right: $gutter;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      .map {
        display: block;
        height: 600px;
        width: 100%;
      }

      .map-img {
        display: none;
      }
    }
  } // END: .contact & .kontakt
}
