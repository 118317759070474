// MIXINS


// Clearfix
// ===========================================================================
// Usage: @include cf();

@mixin cf() {
  &:after {
    clear: both;
    content: " ";    // *1
    display: block; // *2
    line-height: 0;
  }
}