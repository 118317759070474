// TESTIMONIALS

.tpl-home { // <body>

  // slick overrides

  .slick-prev::before,
  .slick-next::before {
    color: $white;
  }

  .slick-prev,
  .slick-next {
    top: 62.5%;
  }

  .slick-prev {
    left: 20px;
  }

  .slick-next {
    right: 20px;
  }


  @media only screen and (max-width: 1024px) {
    .slick-prev,
    .slick-next {
      display: none;
    }
  }


  .unit.testimonials {

    .grid {
      position: relative;
    }

    @media only screen and (min-width: 740px) { // 2-Column CSS-Grid
      .grid {
        height: 500px;
        overflow: hidden;
        width: 100%;
      }
    } // END: mq

    .testimonials--bg { // img
      display: none;
    }

    @media only screen and (min-width: 740px) { // 2-Column CSS-Grid
      .testimonials--bg { // img
        display: block;
        left: 50%;
        position: absolute;
        transform: translate(-50%, 0);
      }
    } // END: mq


    .slider {
      .subline {
        margin-bottom: 30px;
      }

      .testimonial--slide {
        background-color: $light-grey-transparent;
        padding: 20px;
        margin-bottom: 2rem;
      }

      .testimonial--txt {
        color: $medium-grey;
        font-family: "caflisch-script-pro", sans-serif;
        font-size: 28px;
        line-height: 1;
      }

      .testimonial--src {
        display: block;
        font-size: 13px;
        font-weight: normal;
        
        margin-top: .25em;
        text-transform: uppercase;
      }
    }

    @media only screen and (min-width: 740px) { // 2-Column CSS-Grid
      .slider.slick-slider {

        .slick-dots {
          bottom: 30px;
          left: 0;
        }

        .testimonial--slide {
          background-color: transparent;
          padding: 0;
          margin-bottom: 0;
          align-items: center;
          display: flex;
          flex-shrink: 0;
          height: 405px;
          justify-content: center;
          overflow: hidden;
          width: 955px;
          z-index: 1;

          .testimonial--slide--inner {
            max-width: 720px;
            padding: 3rem 4rem;

            .testimonial--txt {
              color: $white;
              font-size: 36px;
            }

            .testimonial--src {
              color: $white-transparent;
              font-size: 14px;
            }
          }
        }

        .subline {
          margin-bottom: 20px;
        }
      }
    } // END: mq 740




  } // END: .unit.testimonials
} // END: .tpl-home



// @media only screen and (min-width: 592px) and (max-width: 739px) { // 1-Column with extra side padding
// @media only screen and (min-width: 740px) { // 2- & 3-Column CSS-Grid
// @media only screen and (min-width: 740px) and (max-width: 1039px) { // 2-Column CSS-Grid
// @media only screen and (min-width: 1040px) {
  






