// VIDEO

//////////////////////////////////////////////////////////////////
// GRID SETTINGS FOR ALL THREE SECTIONS //////////////////////////
//////////////////////////////////////////////////////////////////

.tpl-home { // <body>
  .unit.video {

    .grid {
      margin: 20px auto 0;
      max-width: 1040px;

      .videoplayer {
        height: 0;
        margin-bottom: 20px;
        overflow: hidden;
        padding-bottom: 56.25%; // Default for 16:9 ratio
        padding-top: 0;
        position: relative;

        iframe {
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    }
  }
}

