// scss-lint:disable MergeableSelector

// COMPETENCIES - STYLES FOR CSS-GRID CAPABLE BROWSERS

//////////////////////////////////////////////////////////////////
// DEBUG COLORING ////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

@if $debug-colors {
  .tpl-home { // <body>
    .competencies,
    .kompetenzen {

      .sidemargins {
        background-color: $debug-y;

        .grid {
          background: susy-svg-grid() no-repeat scroll;
          // box-shadow: inset 0 0 0 5px $debug-r;
          .item {
            // background-color: $debug;
            box-shadow: inset 0 0 0 5px $debug-r;

            &.img {
              outline: 5px solid $debug-r;
              outline-offset: -5px;
            }
          }
        }
      }
    }
  } // END: .tpl-home

  :not(.no-cssgrid) {
    body:before {
      background-color: $debug-b;
      border: 5px solid $debug-b;
      border-radius: 8px;
      color: $grey;
      content: "CSS-Grid";
      display: inline-block;
      font-family: Arial;
      left: 40px;
      padding: 3px 10px;
      position: fixed;
      top: 100px;
      z-index: 5;
    }
  } // END: :not(.no-cssgrid)

  .no-cssgrid {
    body:before {
      background-color: $debug-r;
      border: 5px solid $debug-r;
      border-radius: 8px;
      color: $grey;
      content: "No CSS-Grid";
      display: inline-block;
      font-family: Arial;
      left: 40px;
      padding: 3px 10px;
      position: fixed;
      top: 100px;
      z-index: 5;
    }
  } // END: .no-cssgrid
} // END: @if $debug-colors

//////////////////////////////////////////////////////////////////
// GRID SETTINGS FOR ALL THREE SECTIONS //////////////////////////
//////////////////////////////////////////////////////////////////

.tpl-home { // <body>
  .unit.competencies,
  .unit.kompetenzen {

    .grid {
      margin: 20px auto 0;
      max-width: 1040px;

      .item {
        margin-bottom: 20px;

        p {
          margin-bottom: 20px;
          margin-top: 0;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &.img {
          overflow: hidden;

          img {
            display: block;
            height: auto;
            vertical-align: top;
            width: 100%;
          }
        }
      }
    }
  }
}

@if $css-grid-positioning {
  // @supports (grid-area: auto) {
  :not(.no-cssgrid) {
    .tpl-home {
      .unit.competencies,
      .unit.kompetenzen {

        @media only screen and (min-width: 740px) { // 2- & 3-Column CSS-Grid
          .grid {
            display: grid;
            grid-gap: 20px;
            margin-left: auto;
            margin-right: auto;

            .item {
              margin-bottom: 0;
            }
          }

        }

        @media only screen and (min-width: 740px) and (max-width: 1039px) { // 2-Column CSS-Grid
          .grid {
            grid-template-columns: 1fr 1fr;
          }
        }

        @media only screen and (min-width: 1040px) { // 3-Column CSS-Grid
          .grid {
            grid-template-columns: 1fr 1fr 1fr;
          }
        }
      } // END: .unit
    } // END: .tpl-home
  } // END: @supports
} // END: @if $css-grid-positioning


//////////////////////////////////////////////////////////////////
// GRID SETTINGS FINDEN //////////////////////////////////////////
//////////////////////////////////////////////////////////////////

// mobile first styles

.tpl-home {
  .unit.competencies,
  .unit.kompetenzen {

    .grid1 { // FINDEN
      .item {
        margin-bottom: 20px;
      }

      .item2 {
        // finden1
      }

      .item3 { // finden3
        display: none;
      }

      .item6 { // finden2
        display: none;
      }
    }
    .grid2 { // BEURTEILEN
      .item {
        margin-bottom: 20px;
      }

      .item5,
      .item6 {
        display: none;

        &.img {
          img {
            height: 100%;
            // width: auto;
          }
        }
      }
    }
    .grid3 { // ENTWICKELN
      .item {
        margin-bottom: 20px;
      }

      .item5,
      .item6 {
        display: none;

        &.img {
          img {
            height: 100%;
            // width: auto;
          }
        }
      }
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
// for modern browsers with full grid support, excludes some versions of MS Edge
// Alternative to Modernizr: use @supports (grid-area: auto) {
//                           and @supports not (grid-area: auto) {
////////////////////////////////////////////////////////////////////////////////

@if $css-grid-positioning {

  // @supports (grid-area: auto) {
  :not(.no-cssgrid) {
    .tpl-home { // <body>
      .unit.competencies,
      .unit.kompetenzen {

        //////////////////////////////////////////////////////////////////////////
        // 2- & 3-Column CSS-Grid ////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////

        @media only screen and (min-width: 740px) {
          .grid {
            .item {
              margin-bottom: 0;
            }
          }

          .grid1 {
            .item6 {
              &.img {
                img {
                  height: 100%;
                  width: auto;
                }
              }
            }
          } // END: .grid1
        } // END: mq

        //////////////////////////////////////////////////////////////////////////
        // 2-Column CSS-Grid /////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////

        @media only screen and (min-width: 740px) and (max-width: 1039px) {

          .grid1 {
            // grid-template-rows: auto auto auto auto auto auto;

            .item {
              p {
                margin: 0;
                padding: 0;
              }
            }

            .item1 {
              grid-column: 1 / span 1;
              grid-row: 1 / span 1;
            }

            .item2 {
              grid-column: 1 / span 2;
              grid-row: 2 / span 1;
            }

            .item3 {
              align-self: start;
              display: block;
              grid-column: 2 / span 1;
              grid-row: 3 / span 4;
              margin-bottom: 20px;
            }

            .item4 {
              grid-column: 1 / span 1;
              grid-row: 3 / span 1;
            }

            .item5 {
              grid-column: 1 / span 1;
              grid-row: 4 / span 1;
            }


            .item7 {
              display: block;
              grid-column: 1 / span 1;
              grid-row: 5 / span 1;

              p,
              p:last-child {
                margin-bottom: 40px;
              }
            }
          } // END: .grid1

          .grid2 {
            .item1 {
              grid-column: 1 / span 1;
              grid-row: 1 / span 1;
            }

            .item2 {
              align-self: start;
              grid-column: 2 / span 1;
              grid-row: 1 / span 4;
            }

            .item3 {
              display: block;
              grid-column: 1 / span 1;
              grid-row: 2 / span 1;
            }

            .item4 {
              grid-column: 1 / span 1;
              grid-row: 3 / span 1;
            }

            .item5 {
              grid-column: 1 / span 1;
              grid-row: 4 / span 1;
            }
          } // END: .grid2

          .grid3 {
            .item1 {
              grid-column: 1 / span 1;
              grid-row: 1 / span 1;
            }

            .item2 {
              align-self: start;
              grid-column: 1 / span 1;
              grid-row: 2 / span 3;
            }

            .item3 {
              // display: block;
              grid-column: 2 / span 1;
              grid-row: 2 / span 1;
            }

            .item4 {
              grid-column: 2 / span 1;
              grid-row: 3 / span 1;

              p:last-child {
                margin-bottom: 40px;
              }
            }
          } // END: .grid3
        } // END: mq


        //////////////////////////////////////////////////////////////////////////
        // 3-Column CSS-Grid /////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////

        @media only screen and (min-width: 1040px) {

          .grid1 {
            grid-template-rows: minmax(min-content, min-content) 340px 48px auto;

            .item1 {
              grid-column: 1 / span 1;
              grid-row: 1 / span 1;
            }

            .item2 {
              grid-column: 1 / span 2;
              grid-row: 2 / span 1;
              // height: auto;
            }

            .item3 {
              display: block;
              grid-column: 2 / span 1;
              grid-row: 3 / span 2;
              margin-bottom: -40px;
            }

            .item4 {
              align-self: start;
              grid-column: 1 / span 1;
              grid-row: 3 / span 2;
              margin-bottom: 20px;
            }

            .item5 {
              grid-column: 2 / span 1;
              grid-row: 1 / span 1;
            }

            .item6 {
              display: block;
              grid-column: 3 / span 1;
              grid-row: 1 / span 3;
              max-height: 586px;

              img {
                // max-height: 586px;
              }
            }

            .item7 {
              align-self: start;
              display: block;
              grid-column: 3 / span 1;
              grid-row: 4 / span 1;
              margin-bottom: 20px;
            }
          } // END: .grid1

          .grid2 {
            grid-template-rows: 80px 106px auto 278px;
            margin-bottom: -20px;

            .item1 {
              grid-column: 1 / span 1;
              grid-row: 1 / span 1;
            }

            .item2 { // nuss
              grid-column: 2 / span 1;
              grid-row: 1 / span 3;
              min-height: 345px;
              position: relative;

              img {
                position: absolute;
              }
            }

            .item3 {
              align-self: end;
              grid-column: 1 / span 1;
              grid-row: 2 / span 2;
              height: auto;

            }

            .item4 {
              align-self: start;
              display: block;
              grid-column: 3 / span 1;
              grid-row: 3 / span 2;
              margin-bottom: 20px;

            }

            .item5 { // ruder
              display: block;
              grid-column: 1 / span 2;
              grid-row: 4 / span 1;
              position: relative;

              img {
                position: absolute;
              }
            }

            .item6 { //könige
              display: block;
              grid-column: 3 / span 1;
              grid-row: 1 / span 2;
            }
          } // END: .grid2

          .grid3 {
            grid-template-rows: 80px auto 269px 50px;
            margin-bottom: -20px;

            .item1 {
              grid-column: 1 / span 1;
              grid-row: 1 / span 1;
            }

            .item2 { // whiteboard
              grid-column: 2 / span 2;
              grid-row: 3 / span 1;
              position: relative;

              img {
                position: absolute;
              }
            }

            .item3 {
              align-self: end;
              grid-column: 1 / span 1;
              grid-row: 2 / span 1;
            }

            .item4 {
              grid-column: 3 / span 1;
              grid-row: 1 / span 2;
            }

            .item5 { // pflanze
              display: block;
              grid-column: 2 / span 1;
              grid-row: 1 / span 2;
              position: relative;

              img {
                position: absolute;
              }
            }

            .item6 { // lego
              display: block;
              grid-column: 1 / span 1;
              grid-row: 3 / span 2;
            }
          } // END: .grid3
        } // END: mq
      } // END: .kompetenzen
    } // END: .tpl-home
  } // END: @supports (grid-area: auto)
} // END: @if $css-grid-positioning
// scss-lint:enable MergeableSelector
