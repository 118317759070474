// Divider

// @TODO: 1-Column with extra side padding

//////////////////////////////////////////////////////////////////
// DIVIDER SETTINGS //////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

.divider {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0;
  text-transform: uppercase;
  z-index: -2;

  .outer-wrap {
    flex: 0 1 1040px;

    position: relative;

    .inner-wrap {
      .first {
        display: block;
        font-family: "century-gothic", sans-serif;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: .065rem;
        line-height: 1.35;
        margin: 0;
        padding: 1.5rem $side-padding-s .2rem;
      }

      .second {
        display: block;
        font-family: 'utopia-std-display', serif;
        font-size: 28px;
        font-weight: 400;
        letter-spacing: 2px;
        line-height: 1.1;
        margin: 0;
        padding: 0 $side-padding-s 1.5rem;
      }
    }
  }

  &:before,
  &:after {
    content: "";
    display: block;
    flex: 1 1 auto;
  }
}


@media only screen and (min-width: 592px) and (max-width: 739px) { // 1-Column with extra side padding
  .divider {
    .outer-wrap {
      .inner-wrap {
        .first {
          padding: 1.5rem $side-padding-l .2rem;
        }

        .second {
          padding: 0 $side-padding-l 1.5rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 740px) {
  .divider {
    .outer-wrap {
      min-height: 150px;

      .inner-wrap {
        .first {
          font-size: 14px;
        }

        .second {
          font-size: 42px;
        }
      }
    }
  }
}


@media only screen and (min-width: 1040px) {
  .divider {
    // margin: 0 auto -50px;
    margin: 0 auto;
    position: relative;
    // top: -50px;
    top: 0px;

    .outer-wrap {
      .inner-wrap {
        .first {
          padding: 2.5rem $side-padding-s .2rem;
        }

        .second {
          padding: 0 $side-padding-s 10px;
        }
      }
    }
  }
}

//////////////////////////////////////////////////////////////////
// DIVIDER COLORS ////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

// GREEN ////////////////////////////////////////

.divider-green {
  color: $white;

  .outer-wrap {
    background-color: $dark-green;
  }

  &:before,
  &:after {
    background-color: $dark-green;
  }
}

// BLUE /////////////////////////////////////////

.divider-blue-grey {
  color: $light-medium-grey;

  .outer-wrap {
    background-color: $light-blue;
  }

  &:before,
  &:after {
    background-color: $light-blue;
  }
}

.divider-blue-green {
  color: $dark-green;

  .outer-wrap {
    background-color: $light-blue;
  }

  &:before,
  &:after {
    background-color: $light-blue;
  }
}

// BEIGE ////////////////////////////////////////

.divider-beige-grey {

  color: $grey;

  .outer-wrap {
    background-color: $beige;
  }

  &:before,
  &:after {
    background-color: $beige;
  }
}

.divider-beige-green {

  color: $dark-green;

  .outer-wrap {
    background-color: $beige;
  }

  &:before,
  &:after {
    background-color: $beige;
  }
}

// YELLOW ///////////////////////////////////////

.divider-yellow {

  color: $dark-green;

  .outer-wrap {
    background-color: $yellow-transparent;
  }

  &:before,
  &:after {
    background-color: $yellow-transparent;
  }
}

@media only screen and (min-width: 740px) {
  .divider-yellow {
    .outer-wrap {
      background-color: $yellow-transparent;
    }

    &:before,
    &:after {
      background-color: $yellow-transparent;
    }
  }
}


//////////////////////////////////////////////////////////////////
// DIVIDER PLACEMENTS ////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

// LEFT /////////////////////////////////////////

.divider-left:after {
  background-color: transparent;
}

// RIGHT ////////////////////////////////////////

.divider-right:before {
  background-color: transparent;
}

// FULL /////////////////////////////////////////

.divider-full:before,
.divider-full:after {
  // no action
}

// CENTER ///////////////////////////////////////

.divider-center:before,
.divider-center:after {
  background-color: transparent;
}

//////////////////////////////////////////////////////////////////
// DIVIDER EXTRA VARIANTS ////////////////////////////////////////
//////////////////////////////////////////////////////////////////

// KOMPETENZEN > BEURTEILEN /////////////////////

.divider-right-2-col-bg {
  .outer-wrap {
    .inner-wrap {
      .second {
        font-size: 32px;
      }
    }
  }
}

@media only screen and (min-width: 1040px) {
  .divider-right-2-col-bg {
    .outer-wrap {
      margin-left: 360px;
      max-width: 680px;
      text-align: right;
    }
  }
}

// KOMPETENZEN > ENTWICKELN /////////////////////

.divider-full-2-col-text {
  .outer-wrap {
    .inner-wrap {
      .second {
        font-size: 32px;
        padding-top: .5rem;
      }
    }
  }
}


@media only screen and (min-width: 1040px) {
  .divider-full-2-col-text {
    .outer-wrap {
      padding-left: 340px;
    }
  }
}


// DAS HAUS /////////////////////////////////////

.divider-weite {
  margin-top: 0;
  z-index: 1;
}

@media only screen and (min-width: 740px) and (max-width: 1039px) { // 1-Column with extra side padding
  .divider-weite {
    .outer-wrap {
      margin-left: $side-padding-s;
      margin-right: $side-padding-s;
      padding: 0 $side-padding-s;
    }
  }
}

@media only screen and (min-width: 1040px) { // 3-Column CSS-Grid
  .divider-weite {
    margin-bottom: 72px; // = 40px + 2rem
    margin-top: -230px;

    .outer-wrap {
      margin-left: 0;
      margin-right: 340px;
      max-width: 660px;
      padding: $side-padding-s;

    }

    .inner-wrap {
      align-items: flex-start;
      align-self: flex-start;
      display: inline-flex;
      flex-direction: column;
      margin-left: auto;

      .first {
        padding-top: 1.4rem;
      }
    }
  }
}

// DIE MENSCHEN /////////////////////////////////

.divider-we {
  margin-top: 0;
  z-index: 1;
}

@media only screen and (min-width: 740px) and (max-width: 1039px) { // 1-Column with extra side padding
  .divider-we {
    .outer-wrap {
      margin-left: 0;
      margin-right: $side-padding-s;
    }
  }
}

@media only screen and (min-width: 1040px) { // 3-Column CSS-Grid
  .divider-we {
    .outer-wrap {
      margin-left: 0;
      margin-right: 340px;
      max-width: 660px;
      padding: $side-padding-s;

    }

    .inner-wrap {
      align-items: flex-start;
      align-self: flex-start;
      display: inline-flex;
      flex-direction: column;
      margin-left: auto;

      .first {
        padding-top: 1.4rem;
      }
    }
  }
}


.divider-reasons {
  margin-top: 0;
  z-index: -1;
}

@media only screen and (min-width: 740px) and (max-width: 1039px) { // 1-Column with extra side padding
  .divider-reasons {
    .outer-wrap {
      margin-left: 0;
      margin-right: $side-padding-s;
    }
  }
}

@media only screen and (min-width: 1040px) { // 3-Column CSS-Grid
  .divider-reasons {
    margin-top: -72px;

    .outer-wrap {
      margin-left: 0;
      padding: $side-padding-s $side-padding-s $side-padding-s 340px;
    }

    .inner-wrap {
      align-items: flex-start;
      align-self: flex-start;
      display: inline-flex;
      flex-direction: column;
      margin-left: auto;

      .first {
        padding-top: 1.4rem;
      }
    }
  }
}





// TESTIMONIALS /////////////////////////////////

.divider-left-870-bg {
  z-index: 2;

  .outer-wrap {
    align-items: flex-end;
    display: flex;
    max-width: 1000px;
    min-height: auto;
    padding: $side-padding-s;

    .inner-wrap {
      align-items: flex-start;
      align-self: flex-start;
      display: inline-flex;
      flex-direction: column;
      margin-left: auto;

      .first {
        font-size: 14px;
        padding: 0 0 .2rem;
      }

      .second {
        align-self: flex-end;
        font-size: 28px;
        line-height: 1;
        margin-top: 5px;
        padding: 0;
        text-align: right;
      }
    }
  }
}

@media only screen and (min-width: 592px) and (max-width: 739px) { // 1-Column with extra side padding
  .divider-left-870-bg {
    margin-bottom: 50px;
    position: static;

    .outer-wrap {
      padding-left: $side-padding-l;
      padding-right: $side-padding-l;
    }
  }
}

// SLIDER STARTS AT 740

@media only screen and (min-width: 740px) { // 2-Column CSS-Grid
  .divider-left-870-bg {
    margin-bottom: -92px;
    min-height: 150px;
    position: relative;
    top: -92px;

    .outer-wrap {
      padding-top: 2.5rem;

      .inner-wrap {
        .first {
          font-size: 16px;
        }

        .second {
          font-size: 2.25rem;
          // margin-right: .5em;
          margin-top: -1px;
          padding-top: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 1040px) { // 3-Column CSS-Grid

  .divider-left-870-bg {
    .outer-wrap {
      margin-right: 130px;
      max-width: 870px;
      padding-right: $side-padding-s;
      padding-top: 2rem;

      .inner-wrap {
        .second {
          font-size: 2.75rem;
        }
      }
    }
  }
} // END: mq

