// HOME

.tpl-home { // <body>

  .unit.start { // <section>
    position: relative;
    width: 100%;

    .start__logo {
      position: absolute;
      top: 82px;
      width: 100%;
      z-index: 2;

      div {
        margin: 0 auto;
        max-width: 62.5rem;
        position: relative;

        .edit {
          float: right;
          left: 15px;
          position: absolute;
          top: -32px;
        }

        svg {
          display: block;
          height: 136px;
          margin: 0 auto;
          width: 300px;

          path {
            fill: $dark-green;
          }
        }
      }
    }

    .start__image {
      background-color: $gradient-blue-01-60;
      background-position: center 0;
      background-repeat: no-repeat;
      background-size: cover;
      height: calc(100vh - 140px);
      padding-bottom: 140px;
      position: absolute;
      width: 100%;
      z-index: 1;

      &:after {
        background: linear-gradient(to bottom, $gradient-blue-01 0%, $gradient-blue-02 50%, $gradient-blue-03 100%);
        content: " ";
        height: 480px;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    .start__text {
      background-color: $dark-green;
      padding-top: calc(100vh - 140px);
      position: relative;
      width: 100%;
      z-index: 0;

      .top-boxes {
        // padding-top: 1rem;
        color: $white;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 0 auto;
        max-width: 62.5rem;
        padding-bottom: 1rem;
        position: relative;
        width: 100%;

        .top-box {
          flex: 0 1 100%;
          margin-left: 0;
          margin-right: 0;
          margin-top: 1rem;
          padding: 1rem $side-padding-s;

          @media only screen and (min-width: 592px) and (max-width: 719px) {
            margin-left: 20px;
            margin-right: 20px;
            padding: 1rem ($side-padding-l - $side-padding-s);
          }

          &:hover {
            background-color: $dark-green-hover;
            cursor: pointer;
          }

          .start-head {
            .fw-normal {
              font-weight: 400;
              text-transform: uppercase;
            }

            h3 {
              font-size: 37px;
              font-weight: 300;
              line-height: 36px;
              margin-bottom: 0;
              margin-top: 0;
              text-transform: uppercase;
              word-break: break-all;

              @media (max-width: 875px) {
                font-size: 27px; // 37px
              }
            }
          }

          .box-copy-wrapper {
            padding: 1rem 0 .5rem;


            .box-copy-wrapper-layover {
              display: none;
            }

            .box-copy {
              color: $white;
              padding: 0;
            }
          }
        }

      }
    } // END: .hero-text
  } // END: .start

  @media (min-width: 720px) {
    .unit.start {
      height: 100vh;

      .start__text {
        height: 100vh;
        background-color: transparent;
        z-index: auto;

        &:after {
          content: "";
          display: block;
          height: 140px;
          width: 100%;
          position: absolute;
          top: calc(100vh - 140px);
          background-color: $dark-green;
          z-index: 1;
        }

        .top-boxes {
          flex-direction: row;
          padding-top: 0;
          z-index: 2;

          .top-box {
            margin-left: susy-gutter(of 12);
            margin-top: 0;
            padding: 0;
            position: relative;
            width: susy-span(4 of 12);

            &:last-child {
              margin-right: susy-gutter(of 12);
            }

            &:hover {
              background-color: transparent;
            }

            .start-head {
              background-color: $dark-green;
              height: 140px;
              pointer-events: none;
              position: relative;
              // z-index: +10;

              h3 {
                padding-top: 2.1875rem; // 35px
              }
            }

            .box-copy-wrapper {
              bottom: 0;
              height: 70vh;
              padding: 0;
              position: absolute;
              width: 100%;

              &:hover,
              &:active,
              &:focus {
                .box-copy {
                  bottom: 158px;
                  opacity: 1;
                }
              }

              .box-copy-wrapper-layover {
                cursor: pointer;
                display: block;
                height: 100%;
                width: 100%;
              }

              .box-copy {
                background-color: $dark-green-transparent;
                bottom: 28px; // = padding-bottom
                // bottom: 158px; // = padding-bottom
                display: block;
                opacity: 0;
                padding: 15px 15px 20px;
                pointer-events: none;
                position: absolute;
                transition: bottom 400ms ease, opacity 400ms ease;
                z-index: -1;

              }
            }  // END: .box-copy-wrapper
          }
        } // END: .top-boxes
      } // END: .start-text
    } // END: .start
  } // END: mq
} // END: .tpl-home




    // 



    // &.wintermode {

    //   .hero-text {
    //     .top-boxes {
    //       color: $dark-green;

    //       .start-head {
    //         h2 {
    //           // color: $dark-green;
    //         }
    //       }

    //       .box-copy-wrapper {
    //         .box-copy {
    //           // background-color: $white;
    //           // color: $grey;
    //         }
    //       }
    //     }
    //   }
    // }





// @media (max-width: 740px) {

//   #top-boxes {
//     background-color: $white;
//     top: 0;

//     h2 {
//       color: $light-grey;
//     }
//   }

//   .start-head {
//     color: $light-grey;
//     margin-top: 20px;
//     padding-left: 15px;
//   }

//   .box-white {
//     background-color: $dark-green;
//     color: $white;
//     margin-top: 40px;
//   }

//   .start-head-layover,
//   .box-white-wrapper-layover {
//     // display: none;
//   }

//   .box-white-wrapper {
//     height: auto;
//     position: static;
//     width: 100%;
//   }

//   .sticky-menue-wrapper {
//     display: none;
//   }

//   #logo {
//     margin: 83px auto; }
// }





// @media (min-width: 641px) and (max-width: 1024px) {

//   #top-boxes h2 {
//     font-size: 26px;
//     line-height: 29px;
//   }

//   .box-white-wrapper {
//     top: -360px;
//   }

//   .box-white {
//     font-size: 12px;
//   }

// }