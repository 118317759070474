// scss-lint:disable MergeableSelector

// DAS HAUS - STYLES FOR CSS-GRID CAPABLE BROWSERS

$gutter: 20px;

//////////////////////////////////////////////////////////////////
// DEBUG COLORING ////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

@if $debug-colors {

  .tpl-home { // <body>
    .unit.das-haus,
    .unit.the-house {
      .grid { background: susy-svg-grid() no-repeat scroll; }

      .grid1,
      .grid2,
      .grid3,
      .grid4 {
        .item {
          box-shadow: inset 0 0 0 5px $debug-r;

          &.img {
            outline: 5px solid $debug-r;
            outline-offset: -5px;
          }
        }
      }
    }
  }
} // END: @if $debug-colors

//////////////////////////////////////////////////////////////////
// GRID SETTINGS FOR ALL THREE SECTIONS //////////////////////////
//////////////////////////////////////////////////////////////////

.tpl-home { // <body>
  .unit.das-haus,
  .unit.the-house {

    .grid {
      .item {
        p {
          margin-bottom: 20px;
          margin-top: 0;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &.img {
          overflow: hidden;

          img {
            display: block;
            height: auto;
            vertical-align: top;
            width: 100%;
          }
        }
      }
    }
  }
}


//////////////////////////////////////////////////////////////////
// GRID SETTINGS /////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

// mobile first styles


.tpl-home { // <body>
  .unit.das-haus,
  .unit.the-house {

    .grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: stretch;
      margin-bottom: 2rem;
      margin-top: 2rem;

      .item {
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }

        &.txt {
          p {
            margin-top: 0;
          }
        }

        &.img {
          img {
            height: auto;
            width: 100%;
          }
        }
      }
    }

    .grid1 {
      margin-top: 0;

      .item5.img,
      .item6.img {
        display: none;
      }

      .item7.txt {
        margin-bottom: 0;

        p:last-child {
          margin-bottom: 0;
        }
      }
    } // END: .grid1

    .grid2 {
      .item4.img,
      .item8.img {
        display: none;
      }

      .item7.txt {
        margin-bottom: 0;

        p:last-child {
          margin-bottom: 0;
        }
      }
    } // END: .grid2

    .grid3 {
      margin-bottom: 0;

      .item3.img,
      .item4.img,
      .item5.img {
        display: none;
      }

      .item6.txt {
        margin-bottom: 2rem;
      }
    } // END: .grid3

    .grid4 {
      .item6.txt p:last-child {
        margin-bottom: 0;
      }
    } // END: .grid4
  }
}

////////////////////////////////////////////////////////////////////////////////
// for modern browsers with full grid support (excl. some versions of MS Edge)
// Alternative to Modernizr: use @supports (grid-area: auto) {
//                           and @supports not (grid-area: auto) {
////////////////////////////////////////////////////////////////////////////////

@if $css-grid-positioning {

  // @supports (grid-area: auto) {
  :not(.no-cssgrid) {
    .tpl-home {
      .unit.das-haus,
      .unit.the-house {

        @media only screen and (min-width: 592px) and (max-width: 739px) { // 1-Column with extra side padding
          // .grid {
          //   padding: 0 $side-padding-l;
          // }
        } // END: mq

        @media only screen and (min-width: 740px) { // 2- & 3-Column CSS-Grid
          .grid {
            display: grid;
            grid-gap: $gutter;
            margin-left: auto;
            margin-right: auto;

            .item {
              margin-bottom: 0;
              margin-top: 0;
            }
          }
        }


        //////////////////////////////////////////////////////////////////////////
        // 2-Column CSS-Grid /////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////

        @media only screen and (min-width: 740px) and (max-width: 1039px) {
          .grid {
            grid-template-columns: 1fr 1fr;

            .item.img ,
            .item.txt {
              margin-top: 0;

              p:first-child {
                margin-top: 0;
              }
            }
          }

          .grid1 {
            grid-template-rows: minmax(min-content, min-content) 387px auto;


            .item2.img {
              grid-column: 1 / span 2;
              grid-row: 2 / span 1;
              overflow: visible;
              position: relative;

              .crop {
                height: 387px;
                overflow: hidden;
                position: absolute;
                right: 0;
                width: 1005px;

                img {
                  height: 500px;
                  width: 1005px;
                }
              }
            }

            .item3.txt {
              grid-column: 2 / span 1;
              grid-row: 1 / span 1;
            }

            .item4.txt {
              grid-column: 1 / span 1;
              grid-row: 3 / span 1;
            }

            .item7.txt {
              grid-column: 2 / span 1;
              grid-row: 3 / span 1;
            }
          } // END: .grid1

          .grid2 {
            .item2.img {
              grid-column: 1 / span 2;
              grid-row: 3 / span 2;
            }

            .item3.txt {
              align-self: end;
              grid-column: 1 / span 1;
              grid-row: 2 / span 1;
            }

            .item4.img {
              grid-column: 2 / span 1;
              grid-row: 1 / span 2;
            }

            .item5.txt {
              grid-column: 1 / span 1;
              grid-row: 5 / span 1;
            }

            .item6.txt {
              align-self: end;
              grid-column: 2 / span 1;
              grid-row: 1 / span 2;
            }

            .item7.txt {
              grid-column: 2 / span 1;
              grid-row: 5 / span 1;
            }

            .item8.img {
              grid-column: 2 / span 1;
              grid-row: 3 / span 3;
            }
          } // END: .grid2

          .grid3 {
            grid-template-rows: auto auto auto auto;

            .item2.img {
              grid-column: 1 / span 2;
              grid-row: 3 / span 1;

              .crop {
                img {
                  height: auto;
                  width: 100%;
                }
              }
            }

            .item3.img {
              grid-column: 2 / span 1;
              grid-row: 1 / span 3;

              img {
                height: 100%;
                width: auto;
              }
            }

            .item4.img {
              grid-column: 2 / span 1;
              grid-row: 1 / span 2;

              img {
                height: 100%;
                width: auto;
              }
            }

            .item5.txt {
              grid-column: 1 / span 1;
              grid-row: 2 / span 1;
            }

            .item6.txt {
              grid-column: 2 / span 1;
              grid-row: 2 / span 1;
              margin-bottom: 0;
            }

          } // END: .grid3

          .grid4 {
            grid-template-rows: auto auto auto;

            .item5.img {
              grid-column: 1 / span 2;
              grid-row: 3 / span 1;

              .crop {
                img {
                  height: auto;
                  width: 100%;
                }
              }
            }
          } // END: .grid4
        }



        //////////////////////////////////////////////////////////////////////////
        // 3-Column CSS-Grid /////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////

        @media only screen and (min-width: 1040px) { // 3-Column CSS-Grid
          .grid {
            grid-template-columns: 1fr 1fr 1fr;

            .item.img ,
            .item.txt {
              margin: 0;

              p:first-child {
                margin: 0;
              }
            }
          }

          .grid1 {
            grid-template-rows: minmax(min-content, min-content) 387px auto;


            .item5.img,
            .item6.img {
              display: block;
            }

            .item2.img {
              grid-column: 1 / span 2;
              grid-row: 2 / span 1;
              overflow: visible;
              position: relative;

              .crop {
                height: 387px;
                overflow: hidden;
                position: absolute;
                right: 0;
                width: 1005px;

                img {
                  height: 500px;
                  width: 1005px;
                }
              }
            }

            .item3.txt {
              grid-column: 2 / span 1;
              grid-row: 1 / span 1;
            }

            .item4.txt {
              grid-column: 1 / span 1;
              grid-row: 3 / span 1;
            }

            .item5.img {
              grid-column: 3 / span 1;
              grid-row: 1 / span 2;
              position: relative;

              img {
                height: 100%;
                left: 0;
                min-width: 320px;
                position: absolute;
                top: 0;
                width: auto;
              }
            }

            .item6.img {
              grid-column: 2 / span 1;
              grid-row: 3 / span 1;
            }

            .item7.txt {
              grid-column: 3 / span 1;
              grid-row: 3 / span 1;
            }

          } // END: .grid1

          .grid2 {
            .item4.img,
            .item8.img {
              display: block;
            }

            .item2.img {
              grid-column: 1 / span 2;
              grid-row: 3 / span 2;
            }

            .item3.txt {
              align-self: end;
              grid-column: 1 / span 1;
              grid-row: 2 / span 1;
            }

            .item4.img {
              grid-column: 2 / span 1;
              grid-row: 1 / span 2;
            }

            .item5.txt {
              grid-column: 1 / span 1;
              grid-row: 5 / span 1;
            }

            .item6.txt {
              grid-column: 2 / span 1;
              grid-row: 5 / span 1;
            }

            .item7.txt {
              grid-column: 3 / span 1;
              grid-row: 1 / span 2;
            }

            .item8.img {
              grid-column: 3 / span 1;
              grid-row: 3 / span 3;
            }
          } // END: .grid2

          .grid3 {
            grid-template-rows: auto auto auto 570px;
            margin-bottom: 20px;
            overflow: visible;

            .item3.img,
            .item4.img,
            .item5.img {
              display: block;
            }

            .item2.img {
              grid-column: 1 / span 3;
              grid-row: 4 / span 1;
              overflow: visible;
              position: relative;

              .crop {
                height: 570px;
                left: 0;
                overflow: hidden;
                position: absolute;
                width: 1250px;

                img {
                  height: 570px;
                  width: 1250px;
                }
              }
            }

            .item3.img {
              grid-column: 2 / span 1;
              grid-row: 1 / span 3;

              img {
                // height: 100%;
                // width: auto;
              }
            }

            .item4.img {
              grid-column: 3 / span 1;
              grid-row: 1 / span 2;

              img {
                // height: 100%;
                // width: auto;
              }
            }

            .item5.txt {
              align-self: end;
              grid-column: 1 / span 1;
              grid-row: 2 / span 2;
            }

            .item6.txt {
              grid-column: 3 / span 1;
              grid-row: 3 / span 1;
            }
          } // END: .grid3

          .grid4 {
            grid-template-rows: auto 247px;

            .item5.img {
              grid-column: 2 / span 2;
              grid-row: 2 / span 1;
              overflow: visible;
              position: relative;

              .crop {
                height: 247px;
                left: 0;
                overflow: hidden;
                position: absolute;
                width: 1005px;

                img {
                  height: 275px;
                  margin-top: -24px;
                  width: auto;
                }
              }
            }
          } // END: .grid4
        } // END: mq
      } // END: .unit
    } // END: .tpl-home
  } // END: @supports
} // END: @if $css-grid-positioning

// scss-lint:enable MergeableSelector
