// Cookie Bar

#cookie-bar {
  background: $cookie-bar-bg;
  color: $cookie-bar-text;

  p {
    font-size: .8rem;
    padding: 0 75px 0 20px;
  }

  .cb-enable,
  .cb-policy,
  .cb-enable:hover,
  .cb-policy:hover {
    background-color: $cookie-bar-en-pol-bg;
    color: $cookie-bar-en-pol-text;
  }
}
