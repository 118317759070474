// HEADLINES


//////////////////////////////////////////////////////////////////
// GENERAL ///////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

h1 {font-size: 2.125rem;}  // = 34px
// h2 {font-size: 1.6875rem;} // = 27px
h3 {font-size: 1.375rem;}  // = 22px
h4 {font-size: 1.125rem;}  // = 18px
h5 {font-size: 1.125rem;}  // = 18px
h6 {font-size: 1rem;}      // = 16px

@media (min-width: 641px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 { line-height: 1.4; }     // = 22,4px

  h1 { font-size: 2.75rem; }   // = 44px
  // h2 { font-size: 2.3125rem; } // = 37px
  h3 { font-size: 1.6875rem; } // = 27px
  h4 { font-size: 1.4375rem; } // = 23px
  h5 { font-size: 1.125rem; }  // = 18px
  h6 { font-size: 1rem; }      // = 16px
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'utopia-std-display', serif;
  font-weight: 400; // default for bold: 700
  letter-spacing: 2px;
}

@media (max-width: 374px) {
  h1 {
    letter-spacing: 1px;
  }
}

//////////////////////////////////////////////////////////////////
// H3 ////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

.unit {
  .unit-header {
    .unit-hl {
      white-space: nowrap; // keeps Die Menschen from breaking on 320px screen width.
    }
  }

  .grid {
    // overflow: hidden;
    .item1.txt {
      h3 {
        color: $dark-green;
        font-size: 36px;
        letter-spacing: 1px;
        line-height: 36px;
        margin-bottom: 3px;
        margin-top: 0;
        padding-bottom: 0;
        padding-top: 0;
        text-transform: uppercase;
      }

      span {
        color: $dark-green;
        display: block;
        font-family: "century-gothic", sans-serif;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 1.5;
        margin-bottom: 1rem;
        margin-top: 0;
        padding-bottom: 0;
        padding-top: 0;
        text-transform: uppercase;
      }

    }
  } // END: .grid
} // END: .unit

@media (min-width: 641px) {
  .unit {
    .grid {
      .item1.txt {
        h3 {
          font-size: 44px;
          line-height: 44px;
          // margin-bottom: 0;
        }

        span {
          // margin-bottom: 0;
        }

        &.smaller {
          h3 {
            font-size: 36px;
            line-height: 36px;
            margin-bottom: 3px;
          }

          span {
            margin-bottom: 1rem;
          }
        }
      }
    } // END: .grid
  } // END: .unit
}
