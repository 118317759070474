// IMPRESSUM

.tpl-impressum { // <body>

  .impressum { // <section>
    position: relative;
    width: 100%;

    .header {
      height: 290px;

      .start__logo {
        position: absolute;
        top: 82px;
        width: 100%;
        z-index: 2;

        div {
          margin: 0 auto;
          max-width: 62.5rem;
          position: relative;

          .edit {
            float: right;
            left: 15px;
            position: absolute;
            top: -32px;
          }

          svg {
            display: block;
            height: 136px;
            margin: 0 auto;
            width: 300px;

            path {
              fill: $dark-green;
            }
          }
        }
      }

      .start__image {
        background-color: $gradient-blue-01-60;
        background-position: center 0;
        background-repeat: no-repeat;
        background-size: 1600px 1068px;
        height: 290px;
        margin-bottom: 40px;
        padding-bottom: 140px;
        position: absolute;
        width: 100%;
        z-index: 1;
      }

      @media only screen and (min-width: 1600px) { // 2-Column Absolute Positioned
        .start__image {
          background-size: cover;
        }
      }
    } // END .header

    .unit-hl {
      margin-bottom: 40px;
    }

    .text {
      margin-bottom: 3rem;
    }

    .text-1 {
      // width: 400px;
    }

    @media only screen and (min-width: 840px) {
      .sidemargins {
        @include cf();
      }

      .text {
        float: left;
      }

      .text-1 {
        margin-right: 20px;
        width: 360px;
      }

      .text-2 {
        width: calc(100% - 380px);
      }
    }
  }

  .tbl-impressum { // table
    border: 0;
    border-collapse: collapse;
    display: table;

    td {
      display: table-cell;
      padding: 5px 15px 5px 0;
      vertical-align: top;

      &:nth-child(2) {
        vertical-align: bottom;
      }
    }
  }
}
